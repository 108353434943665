import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropType from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ElementPortableText from 'src/components/ElementPortableText/ElementPortableText';
import NodeBlogCard from 'src/components/NodeBlogCard/NodeBlogCard';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';
import { useTheme } from 'src/hooks';
import imgFallback from 'src/utils/image';

// eslint-disable-next-line react/prop-types
function BlogPostPage({ location, data: { post } }) {
  const t = useTheme();

  const seo = {
    title: post.pageSeo?.title ?? post.title,
    description: post?.pageSeo?.description ?? post.description,
    image: post.pageSeo?.image?.asset?.url,
    blog: true,
    location,
  };

  return (
    <PageLayout>
      <PageSEO {...seo} />
      <Container fluid className="bg-shapes-node-page px-0 pt-8">
        <Container className="py-xl-8 py-5">
          {/* @Section: Date, Category-tag, Title, User, Sub-title */}
          <Row className="px-xl-5 mb-xl-5">
            {/* Date, Category-tag */}
            <Col xs={12} className="mb-2">
              <Row>
                {/* Date */}
                <Col xs="auto" className="border-right text-muted text-size-sm font-weight-bold mt-1">
                  {new Date(post.publicationDate).toLocaleDateString(t.lang, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </Col>
                {/* Category-tag */}
                <Col xs="auto">
                  <div className={`badge-${post.category.color} text-uppercase px-1`}>{post.category.name}</div>
                </Col>
              </Row>
            </Col>
            {/* Title, User */}
            <Col xs={12} md={6} xl={5}>
              {/* Title */}
              <Col className="px-0 py-2">
                <h1 className="h2 mb-0">{post.title}</h1>
              </Col>
              {/* User */}
              <Col className="px-0 py-3">
                <Row noGutters className="align-items-center">
                  <Col xs="auto">
                    <GatsbyImage
                      image={post.author.image.asset.gatsbyImageData}
                      alt="Author photo"
                      style={{ width: '65px', height: '65px', overflow: 'hidden', borderRadius: '50%', border: 'solid white 3px' }}
                      imgStyle={{ borderRadius: '50%', overflow: 'hidden' }}
                    />
                  </Col>
                  <Col xs="auto" className="pl-3">
                    <Col className="font-weight-black p-0">{post.author.name}</Col>
                    <Col className="text-muted font-weight-black text-size-sm p-0">{post.author.role}</Col>
                  </Col>
                </Row>
              </Col>
            </Col>
            {/* Sub-title */}
            <Col xs={12} md={6} xl={{ span: 5, offset: 1 }} className="py-2">
              <p>{post.intro}</p>
            </Col>
          </Row>
          {/* @Section: Banner-image */}
          <Row className="py-lg-5 mb-xl-3 py-4">
            <Col xs={12}>
              <GatsbyImage
                image={post.postImage?.image?.asset?.gatsbyImageData ?? imgFallback}
                alt={post.postImage?.alt ?? ''}
                loading="eager"
              />
            </Col>
          </Row>
          {/* @Section: Body-content */}
          <Row noGutters className="py-4">
            <Col className="post-editor py3" xs={12}>
              <ElementPortableText content={post._rawBody} variant={['post']} />
            </Col>
          </Row>
        </Container>
      </Container>
      {/* @Section: Related-posts */}
      {post.relatedBlogPosts?.length > 0 && (
        <Container fluid className="bg-darker px-0">
          <Container className="py-6">
            <Row>
              <Col xs={12}>
                <h2 className="text-md-center py-5 text-white">{t.pages.blog.similarPosts}</h2>
              </Col>
              {post.relatedBlogPosts.map((item) => (
                <Col key={item.id} xs={12} md={6} className="py-3">
                  <NodeBlogCard post={item} darkMode />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      )}
    </PageLayout>
  );
}

export const query = graphql`
  query ($id: String!) {
    post: sanityBlogPost(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      pageSeo {
        ...getPageSeo
      }
      _rawBody
      publicationDate
      author {
        name
        role
        image {
          asset {
            gatsbyImageData(width: 65, height: 65, placeholder: BLURRED)
          }
        }
      }
      category {
        id
        name
        color
      }
      postImage {
        alt
        image {
          asset {
            gatsbyImageData(width: 1140, height: 380, placeholder: BLURRED)
          }
        }
      }
      description
      intro
      relatedBlogPosts {
        ...getBlogPostAsCard
      }
    }
  }
`;

BlogPostPage.propTypes = {
  data: PropType.shape({
    nodeBlog: PropType.shape({}),
  }).isRequired,
};

export default BlogPostPage;
